<template>
    <!-- <div class="container"> -->
        <section class="enquiry">
            <div class="enquiry_text">Оставьте заявку на коммерческое предложение и наш менеджер свяжется с вами в ближайшее
              время</div>
            <button class="enquiry_btn" @click="open">Запросить стоимость</button>
        </section>
    <!-- </div> -->
</template>
<script setup>

const emit = defineEmits(["openModal"]);

function open() {
    emit('openModal', 'cost')
}

</script>
<style lang="scss" scoped>
.enquiry {
    @include main-text;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: rem(136);
    margin-top: rem(60);
    width: 100%;

    @include mobile {
        margin-bottom: rem(60);
    }
}

.enquiry_text {
    max-width: rem(455);
    text-align: center;
    margin-bottom: rem(24);
}

.enquiry_btn {

    padding: rem(16) rem(24);
    min-height: rem(50);
    border: rem(2) solid #0D1BF5;
    margin: 0 auto;
    font-family: Comfortaa;
    text-transform: uppercase;
    color: #0D1BF5;

    &:hover {
        background-color: #6FAEE9;
    }



}
</style>