<template>
    <footer class="footer">
        <div class="container">
            <ul class="footer__navigation navigation">
                <li class="navigation__item"><a class="navigation__link" href="/">Главная</a></li>
                <li class="navigation__item"><a class="navigation__link" :href="productsHref">Продукты</a></li>
                <li class="navigation__item"><a class="navigation__link" :href="solutionsHref">Разработка</a></li>
                <li class="navigation__item"><a class="navigation__link" href="support">Техподдержка</a></li>
            </ul>
            <div class="footer__content">
                <div class="footer__start">
                    <div class="footer__logo">
                        <svg width="257" height="48" viewBox="0 0 257 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M80.3281 30.1641H74.2812L73.4492 33H68.0234L74.4805 15.8203H80.2695L86.7266 33H81.1719L80.3281 30.1641ZM79.2148 26.4492L77.3164 20.2734L75.4297 26.4492H79.2148ZM88.4492 15.8203H98.3867C100.043 15.8203 101.312 16.2305 102.195 17.0508C103.086 17.8711 103.531 18.8867 103.531 20.0977C103.531 21.1133 103.215 21.9844 102.582 22.7109C102.16 23.1953 101.543 23.5781 100.73 23.8594C101.965 24.1562 102.871 24.668 103.449 25.3945C104.035 26.1133 104.328 27.0195 104.328 28.1133C104.328 29.0039 104.121 29.8047 103.707 30.5156C103.293 31.2266 102.727 31.7891 102.008 32.2031C101.562 32.4609 100.891 32.6484 99.9922 32.7656C98.7969 32.9219 98.0039 33 97.6133 33H88.4492V15.8203ZM93.8047 22.5586H96.1133C96.9414 22.5586 97.5156 22.418 97.8359 22.1367C98.1641 21.8477 98.3281 21.4336 98.3281 20.8945C98.3281 20.3945 98.1641 20.0039 97.8359 19.7227C97.5156 19.4414 96.9531 19.3008 96.1484 19.3008H93.8047V22.5586ZM93.8047 29.3086H96.5117C97.4258 29.3086 98.0703 29.1484 98.4453 28.8281C98.8203 28.5 99.0078 28.0625 99.0078 27.5156C99.0078 27.0078 98.8203 26.6016 98.4453 26.2969C98.0781 25.9844 97.4297 25.8281 96.5 25.8281H93.8047V29.3086ZM123.559 15.8203V33H118.566V23.5664L112.133 33H107.129V15.8203H112.133V25.3242L118.602 15.8203H123.559ZM145.133 36.7383H140.855V33H129.887V36.7383H125.621V29.0391H127.391C128.383 26.2656 128.879 23.1367 128.879 19.6523V15.8203H143.281V29.0391H145.133V36.7383ZM137.973 29.0391V20.0391H133.707V20.2617C133.707 22.6367 133.305 25.5625 132.5 29.0391H137.973ZM157.789 30.1641H151.742L150.91 33H145.484L151.941 15.8203H157.73L164.188 33H158.633L157.789 30.1641ZM156.676 26.4492L154.777 20.2734L152.891 26.4492H156.676Z"
                                fill="#8BAEC8" />
                            <path
                                d="M164.668 24.8906H171.605V28.582H164.668V24.8906ZM184.871 26.6836L188.234 27.75C187.719 29.625 186.859 31.0195 185.656 31.9336C184.461 32.8398 182.941 33.293 181.098 33.293C178.816 33.293 176.941 32.5156 175.473 30.9609C174.004 29.3984 173.27 27.2656 173.27 24.5625C173.27 21.7031 174.008 19.4844 175.484 17.9062C176.961 16.3203 178.902 15.5273 181.309 15.5273C183.41 15.5273 185.117 16.1484 186.43 17.3906C187.211 18.125 187.797 19.1797 188.188 20.5547L184.754 21.375C184.551 20.4844 184.125 19.7812 183.477 19.2656C182.836 18.75 182.055 18.4922 181.133 18.4922C179.859 18.4922 178.824 18.9492 178.027 19.8633C177.238 20.7773 176.844 22.2578 176.844 24.3047C176.844 26.4766 177.234 28.0234 178.016 28.9453C178.797 29.8672 179.812 30.3281 181.062 30.3281C181.984 30.3281 182.777 30.0352 183.441 29.4492C184.105 28.8633 184.582 27.9414 184.871 26.6836ZM190.848 33V15.8203H196.039L199.156 27.5391L202.238 15.8203H207.441V33H204.219V19.4766L200.809 33H197.469L194.07 19.4766V33H190.848ZM226.379 33H222.605L221.105 29.0977H214.238L212.82 33H209.141L215.832 15.8203H219.5L226.379 33ZM219.992 26.2031L217.625 19.8281L215.305 26.2031H219.992ZM228.23 33V15.8203H233.797C235.906 15.8203 237.281 15.9062 237.922 16.0781C238.906 16.3359 239.73 16.8984 240.395 17.7656C241.059 18.625 241.391 19.7383 241.391 21.1055C241.391 22.1602 241.199 23.0469 240.816 23.7656C240.434 24.4844 239.945 25.0508 239.352 25.4648C238.766 25.8711 238.168 26.1406 237.559 26.2734C236.73 26.4375 235.531 26.5195 233.961 26.5195H231.699V33H228.23ZM231.699 18.7266V23.6016H233.598C234.965 23.6016 235.879 23.5117 236.34 23.332C236.801 23.1523 237.16 22.8711 237.418 22.4883C237.684 22.1055 237.816 21.6602 237.816 21.1523C237.816 20.5273 237.633 20.0117 237.266 19.6055C236.898 19.1992 236.434 18.9453 235.871 18.8438C235.457 18.7656 234.625 18.7266 233.375 18.7266H231.699ZM247.777 33V18.7266H242.68V15.8203H256.332V18.7266H251.246V33H247.777Z"
                                fill="white" />
                            <rect x="2" y="2" width="44" height="44" stroke="white" stroke-width="4" />
                            <path
                                d="M19.416 22H12.344C12.1413 22 11.992 21.9573 11.896 21.872C11.8107 21.776 11.768 21.6267 11.768 21.424V19.488C11.768 19.2853 11.8107 19.1413 11.896 19.056C11.992 18.96 12.1413 18.912 12.344 18.912H14.136V13.568H12.664C12.4613 13.568 12.312 13.5253 12.216 13.44C12.1307 13.344 12.088 13.1947 12.088 12.992V11.056C12.088 10.8533 12.1307 10.7093 12.216 10.624C12.312 10.528 12.4613 10.48 12.664 10.48H17.304C17.5067 10.48 17.6507 10.528 17.736 10.624C17.832 10.7093 17.88 10.8533 17.88 11.056V18.912H19.416C19.6187 18.912 19.7627 18.96 19.848 19.056C19.944 19.1413 19.992 19.2853 19.992 19.488V21.424C19.992 21.6267 19.944 21.776 19.848 21.872C19.7627 21.9573 19.6187 22 19.416 22Z"
                                fill="white" />
                            <path
                                d="M36.416 39H29.344C29.1413 39 28.992 38.9573 28.896 38.872C28.8107 38.776 28.768 38.6267 28.768 38.424V36.488C28.768 36.2853 28.8107 36.1413 28.896 36.056C28.992 35.96 29.1413 35.912 29.344 35.912H31.136V30.568H29.664C29.4613 30.568 29.312 30.5253 29.216 30.44C29.1307 30.344 29.088 30.1947 29.088 29.992V28.056C29.088 27.8533 29.1307 27.7093 29.216 27.624C29.312 27.528 29.4613 27.48 29.664 27.48H34.304C34.5067 27.48 34.6507 27.528 34.736 27.624C34.832 27.7093 34.88 27.8533 34.88 28.056V35.912H36.416C36.6187 35.912 36.7627 35.96 36.848 36.056C36.944 36.1413 36.992 36.2853 36.992 36.488V38.424C36.992 38.6267 36.944 38.776 36.848 38.872C36.7627 38.9573 36.6187 39 36.416 39Z"
                                fill="white" />
                            <path
                                d="M36.704 18.976C36.704 19.4667 36.6347 19.9147 36.496 20.32C36.3573 20.7147 36.1067 21.056 35.744 21.344C35.3813 21.6213 34.88 21.84 34.24 22C33.6107 22.16 32.8053 22.24 31.824 22.24C30.8427 22.24 30.032 22.16 29.392 22C28.7627 21.84 28.2667 21.6213 27.904 21.344C27.5413 21.056 27.2853 20.7147 27.136 20.32C26.9973 19.9147 26.928 19.4667 26.928 18.976V13.52C26.928 13.0293 26.9973 12.5867 27.136 12.192C27.2853 11.7867 27.5413 11.44 27.904 11.152C28.2667 10.864 28.7627 10.64 29.392 10.48C30.032 10.32 30.8427 10.24 31.824 10.24C32.8053 10.24 33.6107 10.32 34.24 10.48C34.88 10.64 35.3813 10.864 35.744 11.152C36.1067 11.44 36.3573 11.7867 36.496 12.192C36.6347 12.5867 36.704 13.0293 36.704 13.52V18.976ZM30.544 18.4C30.544 18.6133 30.6187 18.7947 30.768 18.944C30.928 19.0827 31.28 19.152 31.824 19.152C32.368 19.152 32.7147 19.0827 32.864 18.944C33.024 18.7947 33.104 18.6133 33.104 18.4V14.096C33.104 13.8933 33.024 13.7227 32.864 13.584C32.7147 13.4347 32.368 13.36 31.824 13.36C31.28 13.36 30.928 13.4347 30.768 13.584C30.6187 13.7227 30.544 13.8933 30.544 14.096V18.4Z"
                                fill="white" />
                            <path
                                d="M11.296 35.976C11.296 36.4667 11.3653 36.9147 11.504 37.32C11.6427 37.7147 11.8933 38.056 12.256 38.344C12.6187 38.6213 13.12 38.84 13.76 39C14.3893 39.16 15.1947 39.24 16.176 39.24C17.1573 39.24 17.968 39.16 18.608 39C19.2373 38.84 19.7333 38.6213 20.096 38.344C20.4587 38.056 20.7147 37.7147 20.864 37.32C21.0027 36.9147 21.072 36.4667 21.072 35.976V30.52C21.072 30.0293 21.0027 29.5867 20.864 29.192C20.7147 28.7867 20.4587 28.44 20.096 28.152C19.7333 27.864 19.2373 27.64 18.608 27.48C17.968 27.32 17.1573 27.24 16.176 27.24C15.1947 27.24 14.3893 27.32 13.76 27.48C13.12 27.64 12.6187 27.864 12.256 28.152C11.8933 28.44 11.6427 28.7867 11.504 29.192C11.3653 29.5867 11.296 30.0293 11.296 30.52V35.976ZM17.456 35.4C17.456 35.6133 17.3813 35.7947 17.232 35.944C17.072 36.0827 16.72 36.152 16.176 36.152C15.632 36.152 15.2853 36.0827 15.136 35.944C14.976 35.7947 14.896 35.6133 14.896 35.4V31.096C14.896 30.8933 14.976 30.7227 15.136 30.584C15.2853 30.4347 15.632 30.36 16.176 30.36C16.72 30.36 17.072 30.4347 17.232 30.584C17.3813 30.7227 17.456 30.8933 17.456 31.096V35.4Z"
                                fill="white" />
                        </svg>
                    </div>
                    <div class="footer__text">Лидер в разработке и производстве электронных устройств, соответствующих
                        требованиям регуляторов (ФСБ России, ФСТЭК России, МО РФ)
                    </div>
                </div>
                <div class="footer__end" id="contacts">
                    <div class="footer__phone">
                        <a href="tel:+74993978922">+7 (499) 397 89 22</a>
                    </div>
                    <div class="footer__email">
                        <a href="mailto:info@avida-smart.ru">info@avida-smart.ru</a>
                    </div>
                    <div class="footer__address">109428, город Москва, Рязанский пр-кт, д. 16 стр. 4, этаж 3 ком. 10</div>
                </div>
            </div>
            <div class="footer__copyright"><span id="copy-year">{{ currentYear }}</span> © Все права защищены.
            </div>
        </div>

    </footer>
</template>
<script setup>
import { computed } from 'vue';


const currentYear = computed(() => {
    const year = new Date().getFullYear();
    return year.toString();
});

const productsHref = window.location.pathname == '/' ? '#products' : '/#products'
const solutionsHref = window.location.pathname == '/' ? '#solutions' : '/#solutions'
</script>
<style lang="scss" scoped>
.footer {
    background-color: #020531;
    padding: 32px 0 40px;
    position: relative;
    color: #fff;
    width: 100%;
    min-width: 1120px;

    @include mobile {
        width: 100%;
        min-width: rem(300);
    }

    &::before {
        content: "";
        position: absolute;
        background-color: #020531;
        width: 100%;
        height: 4px;
        top: -6px;
    }

    &__navigation {
        @include mobile {
            display: none;
        }

        @include desktop {
            margin: 0 auto rem(20);
            justify-content: center;
        }
    }

    &__content {
        @include desktop {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__logo {
        width: rem(257);
        height: rem(48);

        @include mobile {
            margin: auto;
        }

        svg {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    &__text {
        @include main-text;
        width: rem(243);
        margin-top: rem(20);

        @include mobile {
            margin-left: auto;
            margin-right: auto;
            max-width: rem(400)
        }
    }

    &__start {
        @include mobile {
            text-align: center;
        }
    }

    &__end {
        @include mobile {
            margin-top: rem(48);
            text-align: center;
        }

        @include desktop {
            text-align: right;
        }
    }

    &__phone {
        @include section-title;

        &:hover {
            color: #A8D1F0;
        }
    }

    &__email {
        @include main-text;
        margin-top: rem(8);

        &:hover {
            color: #A8D1F0;
        }
    }

    &__address {
        @include main-text;
        width: rem(330);

        @include mobile {
            margin: rem(32) auto;
        }

        @include desktop {
            margin-top: rem(32);
        }
    }

    &__copyright {
        text-align: center;
        font-size: rem(12);
        line-height: rem(13);
        font-family: Comfortaa;

        @include mobile {
            margin-top: rem(32);
        }

        @include desktop {
            margin-top: rem(40);
        }
    }

    &__navigation {
        font-family: Comfortaa;
    }
}
</style>  