export const text_solutions_description = 'Avida-Smart предлагает безопасные и надежные промышленные решения, адаптированные к вашим потребностям. Наша передовая разработка программного обеспечения помогут вам оставаться впереди конкурентов.'
export const solutions_list = [
    {
        title: 'Система биометрической идентификации в соответствии со стандартами ГОСТ',
        picture: 'solutions__image_1@1x.jpg',
        descr_text: 'Система предназначена для регистрации и проверки отпечатков пальцев. Алгоритм работы системы соответствует требованиям:',
        gost: ['ГОСТ Р ИСО/МЭК 19794-4-2014', 'ГОСТ Р ИСО/МЭК 19794-2-2013'],
        bold: 'Примеры использования:',
        solutions_part_list: ['аутентификация устройств(смартфоны, ноутбуки, флешки и т.д.)', 'идентификация преступников', 'идентификация в банковском секторе.'],
        presentation: '/pdf/Avida-Smart_Biometrics.pdf',
        btn_text: 'Подробнее',
        id: 'biometric-img',
        page: 'biometric.html',
    },
    {
        title: 'СЕРВИС РАСПОЗНАВАНИЯ И КОНВЕРТАЦИИ ДОКУМЕНТОВ',
        picture: 'solutions__image_2@1x.jpg',
        descr_text: 'Идеальный инструмент для цифровизации бумажных документов. Позволяет оперативно и качественно оцифровать и конвертировать документы организации в единый формат (PDF-A, с текстом и оптимальным сжатием).',
        gost: null,
        bold: 'Назначение ПО:',
        solutions_part_list: ['Оцифровка документов', 'Конвертация документов в единый формат (PDF-A, с текстом и оптимальным сжатием)'],
        presentation: '/pdf/Avida-SmartOCR_Server_s.pdf',
        btn_text: 'Подробнее',
        id: 'docs-img',
        page: 'smartocr.html',
    },
    {
        title: 'СИСТЕМА ОБРАБОТКИ И РАСПРЕДЕЛЕНИЯ СООБЩЕНИЙ ПОЛЬЗОВАТЕЛЕЙ В SD',
        picture: 'solutions__image_3@1x.jpg',
        descr_text: 'Система совершенствования процессов сбора, учета, обработки и анализа обращений пользователей в целях улучшения качества продукта, уменьшения время отклика, а также расстановка приоритетов.',
        gost: null,
        bold: 'Система обеспечивает:',
        solutions_part_list: ['максимальную степень унификации технических решений', 'надежность функционирования(работа 24 / 7)', 'простота в эксплуатации, эргономичность интерфейса'],
        presentation: '/pdf/Avida-Smart_Data_Miner.pdf',
        btn_text: 'Подробнее',
        id: 'users-img',
        page: 'dextractor.html',
    }
]





